import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import '../Assets/Css/style.css';
import { useNavigate } from 'react-router-dom';

function SpellingActivity() {
  const [words, setWords] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [selectedLetters, setSelectedLetters] = useState('');
  const [score, setScore] = useState(0);
  const [error, setError] = useState(null);
  const [selectedRange, setSelectedRange] = useState([0, 10]); // Default range is 1-25
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch and parse the CSV file to get the words
    fetch('./Voca.csv')
      .then(response => response.text())
      .then(csvData => {
        const parsedData = Papa.parse(csvData, { header: false });
        setWords(parsedData.data.map(row => row[0]));
      })
      .catch(error => {
        console.error('Error fetching/parsing CSV file:', error);
        setError(error.message);
      });
  }, []);

  const currentWord = words[currentWordIndex];
  const displayLettersCount = 2; // Number of letters to display at the start

  const handleSpeak = () => {
    const utterance = new SpeechSynthesisUtterance(words[currentWordIndex]);
    speechSynthesis.speak(utterance);
  };
  const displayedWord = (word) => {
    const wordLength = word.length;
    if (wordLength <= 5) {
      return word.slice(0, displayLettersCount) + '_'.repeat(wordLength - displayLettersCount);
    }
    const firstPart = word.slice(0, displayLettersCount);
    const lastPart = word.slice(-1);
    return firstPart + '_'.repeat(wordLength - displayLettersCount - 1) + lastPart;
  };

  const handleCheckAnswer = () => {
    const firstPart = currentWord.slice(0, displayLettersCount);
    const lastPart = currentWord.length > 5 ? currentWord.slice(-1) : '';
    const missingPart = currentWord.slice(displayLettersCount, currentWord.length > 5 ? -1 : undefined);

    const normalizedSelected = selectedLetters.replace(/\s/g, '').toLowerCase();
    const normalizedMissingPart = missingPart.replace(/\s/g, '').toLowerCase();

    if (normalizedSelected === normalizedMissingPart) {
      setScore(prevScore => prevScore + 1);
      alert('Correct!');
    } else {
      alert(`Incorrect! The correct answer is "${currentWord}"`);
    }
  };

  const handleNextWord = () => {
    setSelectedLetters('');
    setCurrentWordIndex(prevIndex => prevIndex + 1);
  };

  const handlePlayAgain = () => {
    setSelectedLetters('');
    setCurrentWordIndex(selectedRange[0]);
    setScore(0);
  };

  const handleRangeChange = (start, end) => {
    setSelectedRange([start, end]);
    setCurrentWordIndex(start);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  const currentWords = words.slice(selectedRange[0], selectedRange[1]);
  const isGameOver = currentWordIndex >= selectedRange[1];

  // const currentWords = words.slice(selectedRange[0], selectedRange[1]);

  return (
    <div className="game-container" style={{ display: 'flex' }}>
    <div className="left-panel" style={{ flex: '1', borderRight: '1px solid #ddd', padding: '10px' }}>
        <h2>Questions</h2>
        {currentWords.map((word, index) => (
          <div
            key={index}
            className={`question-item ${currentWordIndex === (selectedRange[0] + index) ? 'active' : ''}`}
            onClick={() => setCurrentWordIndex(selectedRange[0] + index)}
            style={{ cursor: 'pointer', marginBottom: '10px' }}
          >
            Question {selectedRange[0] + index + 1}
          </div>
        ))}
        </div>
      
        <div className="main-panel" style={{ flex: '2', padding: '20px' }}>
  <div className="school-board">
    <h1>Spell It</h1>
    <div className="score" style={{ float: 'right' }}>Score: {score}</div>
  </div>
  {currentWordIndex < words.length && !isGameOver ? (
    <div>
      <h2>Word {currentWordIndex + 1} of {selectedRange[1] - selectedRange[0]}</h2>
      <p>Enter the missing letters:</p>
      <div>
        <button onClick={handleSpeak}>
          <span role="img" aria-label="speaker">🔊</span> Hear the word
        </button>
        {displayedWord(currentWord).split('').map((letter, index) => (
          <input
            key={index}
            type="text"
            maxLength="1"
            value={letter === '_' ? selectedLetters[index - displayLettersCount] || '' : letter}
            onChange={(e) => {
              if (letter === '_') {
                const newLetters = selectedLetters.split('');
                newLetters[index - displayLettersCount] = e.target.value;
                setSelectedLetters(newLetters.join(''));
              }
            }}
            style={{ width: '20px', marginRight: '5px' }}
            disabled={letter !== '_'}
          />
        ))}
      </div>
      <button onClick={handleCheckAnswer}>Check Answer</button>
      <button onClick={handleNextWord}>Next Word</button>
    </div>
  ) : (
    <div>
      <h2>Game Over</h2>
      <p>Your final score is: {score}</p>
      <button onClick={handlePlayAgain}>Play Again</button>
    </div>
  )}
</div>


      <div className="right-panel" style={{ flex: '1', borderLeft: '1px solid #ddd', padding: '10px' }}>
        {Array.from({ length: Math.ceil(words.length / 10) }, (_, index) => (
          <button
            key={index}
            onClick={() => handleRangeChange(index * 10, (index + 1) * 10)}
            style={{ margin: '0 5px' }}
          >
            {index * 10 + 1} - {(index + 1) * 10}
          </button>
        ))}
          <h3>Next Game</h3>
        <button onClick={() => navigate('/about-us')}>Spelling Activity</button>
        <button onClick={() => navigate('/Services')}>Writing Activity</button>
        <button onClick={() => navigate('/booking')}>Similar Words</button>
        
      </div>

    </div>
  );
}

export default SpellingActivity;
