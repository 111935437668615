import React, { useState, useEffect } from 'react';
import { FaPlay, FaPause } from 'react-icons/fa';

function WordDictation() {
  const [words, setWords] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  // Handle text input
  const handleTextInput = (event) => {
    const text = event.target.value;
    const wordArray = text.split(/\s+/);
    setWords(wordArray);
    setIsCompleted(false); // Reset the completion status
    setCurrentWordIndex(0); // Reset current index
  };

  // Text-to-Speech function to dictate the current word
  const speakWord = (word) => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(word);
      window.speechSynthesis.speak(utterance);
    } else {
      alert('Speech Synthesis is not supported in this browser.');
    }
  };

  // Play/Pause Dictation
  const togglePlayPause = () => {
    if (isPlaying) {
      // Pause the dictation
      clearInterval(intervalId);
      setIsPlaying(false);
      setIntervalId(null);
    } else {
      // Start or resume the dictation
      setIsPlaying(true);
      speakWord(words[currentWordIndex]);

      const id = setInterval(() => {
        setCurrentWordIndex((prevIndex) => {
          const nextIndex = prevIndex + 1;

          if (nextIndex < words.length) {
            speakWord(words[nextIndex]);
            return nextIndex;
          } else {
            // Stop dictation when done
            clearInterval(id);
            setIsPlaying(false);
            setIntervalId(null);
            setIsCompleted(true);
            return 0;
          }
        });
      }, 30000); // 30-second interval

      setIntervalId(id);
    }
  };

  // Finish Dictation
  const finishDictation = () => {
    setIsPlaying(false);
    clearInterval(intervalId);
    setIntervalId(null);
    setIsCompleted(true);
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => clearInterval(intervalId);
  }, [intervalId]);

  return (
    <div style={styles.container}>
      <h1>Word Dictation</h1>
      
      {/* Text area for word input */}
      <textarea
        style={styles.textArea}
        placeholder="Enter your words here..."
        onChange={handleTextInput}
      ></textarea>

      {/* Display hidden words */}
      <div style={styles.wordsContainer}>
        {words.map((word, index) => (
          <span key={index} style={styles.hiddenWord}>
            {isCompleted ? word : '****'}
          </span>
        ))}
      </div>

      {/* Control buttons */}
      <div style={styles.controls}>
        <button onClick={togglePlayPause} style={styles.playPauseButton}>
          {isPlaying ? <FaPause /> : <FaPlay />}
        </button>
        <button onClick={finishDictation} style={styles.finishButton}>
          Finish
        </button>
      </div>

      {/* Show words when completed */}
      {isCompleted && (
        <div style={styles.wordList}>
          <h3>Words:</h3>
          <p>{words.join(', ')}</p>
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    maxWidth: '500px',
    margin: 'auto',
    padding: '20px',
    // border: '1px solid #ccc',
    borderRadius: '8px',
    textAlign: 'center'
  },
  textArea: {
    // width: '100%',
    // height: '100px',
    // padding: '10px',
    // marginBottom: '20px',
    wrap:'hard'
  },
  wordsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    justifyContent: 'center',
    marginBottom: '20px'
  },
  hiddenWord: {
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '16px',
    backgroundColor: '#f0f0f0'
  },
  controls: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    marginBottom: '20px'
  },
  playPauseButton: {
    padding: '10px',
    fontSize: '16px',
    borderRadius: '4px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    cursor: 'pointer'
  },
  finishButton: {
    padding: '10px',
    fontSize: '16px',
    borderRadius: '4px',
    backgroundColor: '#f44336',
    color: 'white',
    border: 'none',
    cursor: 'pointer'
  },
  wordList: {
    marginTop: '20px',
    fontSize: '16px',
    textAlign: 'center'
  }
};

export default WordDictation;
