import React from "react";
import { Link } from "react-router-dom";
//import "../Assets/Css/Blogs.css";
//import diseaseManagement from "../Assets/Images/diseaseMgmnt.jpg";

const BlogTemplate = () => {
  return (
    <div className="blog-template">
      <header className="blog-header">
        <h1 className="blog-title">The Importance of Balanced Nutrition</h1>
        <p className="blog-date">October 01, 2024</p>
        <p className="blog-keywords">Nutrition, Health, Wellness</p>
      </header>

      {/* <img
        src={diseaseManagement}
        alt="Nutrition-related"
        className="blog-image"
      /> */}

      <div className="blog-content">
        <section className="introduction">
          <h2>Introduction</h2>
          <p>
            In today’s fast-paced world, maintaining a balanced diet is more
            crucial than ever. A proper diet can help improve overall health,
            boost energy levels, and reduce the risk of chronic diseases.
          </p>
        </section>

        <section className="main-content">
          <h2>What is Balanced Nutrition?</h2>
          <p>
            Balanced nutrition means consuming the right proportions of
            carbohydrates, proteins, fats, vitamins, and minerals. It’s
            essential for your body to function properly.
          </p>

          <h3>Macronutrients</h3>
          <ul>
            <li>
              <strong>Carbohydrates:</strong> Provide energy.
            </li>
            <li>
              <strong>Proteins:</strong> Essential for growth and repair.
            </li>
            <li>
              <strong>Fats:</strong> Important for brain health.
            </li>
          </ul>

          <h3>Micronutrients</h3>
          <p>
            Vitamins and minerals are critical for various bodily functions.
            Incorporating a variety of fruits and vegetables can help meet these
            needs.
          </p>
        </section>

        <section className="tips">
          <h2>Nutrition Tips</h2>
          <ul>
            <li>Eat a rainbow of fruits and vegetables.</li>
            <li>Stay hydrated by drinking plenty of water.</li>
            <li>Plan your meals to avoid unhealthy snacking.</li>
            <li>Listen to your body’s hunger signals.</li>
          </ul>
        </section>

        <section className="conclusion">
          <h2>Conclusion</h2>
          <p>
            Adopting a balanced diet is a key step towards achieving a healthier
            lifestyle. Consider consulting a nutritionist for personalized
            advice tailored to your needs.
          </p>
        </section>

        <footer className="blog-footer">
          <h3>Share this post:</h3>
          <div className="social-media-links">
            <a href="#" target="_blank" rel="noopener noreferrer">
              Facebook
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              Twitter
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              Instagram
            </a>
          </div>
          <Link to="/blogs" className="back-link">
            Back to Blogs
          </Link>
        </footer>
      </div>
    </div>
  );
};

export default BlogTemplate;
