import React from 'react';
import { useNavigate } from 'react-router-dom';

function HomePage() {
  const navigate = useNavigate();

  const games = [
    { name: 'Dictator', path: '/dict' },
    { name: 'Story book', path: '/story' },
    { name: 'Wordset', path: '/Similar' },
    { name: 'Missing Letter', path: '/spell' },
    { name: 'write', path: '/write' },
  ];

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Select a Game</h2>
      <div style={styles.grid}>
        {games.map((game, index) => (
          <button
            key={index}
            style={styles.card}
            onClick={() => navigate(game.path)}
          >
            {game.name}
          </button>
        ))}
      </div>
    </div>
  );
}

const styles = {
  container: {
    maxWidth: '500px',
    margin: 'auto',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
    textAlign: 'center',
  },
  header: {
    marginBottom: '20px',
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '15px',
  },
  card: {
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#f1f1f1',
    boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#333',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
  },
};

export default HomePage;
