import React, { useState, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import '../Assets/Css/style.css'; // Ensure this path matches your actual CSS file

const DragAndDropGame = () => {
  const [wordSets, setWordSets] = useState([]);
  const [currentSetIndex, setCurrentSetIndex] = useState(0);
  const [droppedWords, setDroppedWords] = useState([]);
  const [isCorrect, setIsCorrect] = useState([]);
  const [allWords, setAllWords] = useState([]);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    fetch('./wordSets.json')
      .then((response) => response.json())
      .then((data) => {
        setWordSets(data);
        initializeGame(data, 0);
      });
  }, []);

  const initializeGame = (wordSets, setIndex) => {
    const newWords = generateWords(wordSets, setIndex, 25);
    setDroppedWords(Array(wordSets[setIndex].relatedWords.length).fill(''));
    setIsCorrect(Array(wordSets[setIndex].relatedWords.length).fill(null));
    setAllWords(shuffleArray(newWords));
    setSubmitted(false);
  };

  const generateWords = (wordSets, currentIndex, limit) => {
    const correctWords = wordSets[currentIndex].relatedWords;
    const allWords = wordSets.flatMap((set) => set.relatedWords).filter(word => !correctWords.includes(word));
    const shuffledWords = shuffleArray(allWords).slice(0, limit - correctWords.length);
    return [...correctWords, ...shuffledWords];
  };

  const shuffleArray = (array) => array.sort(() => 0.5 - Math.random());

  const handleSubmit = () => {
    const correctSet = wordSets[currentSetIndex].relatedWords;
    const newIsCorrect = droppedWords.map((word, index) => word === correctSet[index]);
    setIsCorrect(newIsCorrect);

    // Check if all are correct and increment score
    if (newIsCorrect.every((isCorrect) => isCorrect)) {
      setScore((prevScore) => prevScore + 1);
    }
    setSubmitted(true);
  };

  const handleNextSet = () => {
    const nextIndex = currentSetIndex + 1;
    if (nextIndex < wordSets.length) {
      setCurrentSetIndex(nextIndex);
      initializeGame(wordSets, nextIndex);
    } else {
      setGameOver(true);
    }
  };

  const handleWordDrop = (word, index) => {
    const newDroppedWords = [...droppedWords];
    newDroppedWords[index] = word;
    setDroppedWords(newDroppedWords);
  };

  const DraggableWord = ({ word }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: 'word',
      item: { word },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }));

    return (
      <div ref={drag} className="draggable-word" style={{ opacity: isDragging ? 0.5 : 1 }}>
        {word}
      </div>
    );
  };

  const DropArea = ({ index, onDrop }) => {
    const [{ isOver }, drop] = useDrop(() => ({
      accept: 'word',
      drop: (item) => onDrop(item.word, index),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }));

    const isCorrectAnswer = isCorrect[index];
    const backgroundColor = submitted
      ? isCorrectAnswer
        ? '#a4df88' // Green for correct answer
        : droppedWords[index] !== '' && droppedWords[index] !== wordSets[currentSetIndex].relatedWords[index]
        ? '#f28585' // Red for incorrect answer
        : '#fff' // Unfilled drop areas after submit
      : '#fff';

    return (
      <div
        ref={drop}
        className="drop-area"
        style={{
          backgroundColor: isOver ? '#f0f0f0' : backgroundColor,
          border: '1px solid #ccc',
          minHeight: '50px',
          padding: '10px',
          textAlign: 'center',
        }}
      >
        {submitted && !droppedWords[index]
          ? wordSets[currentSetIndex].relatedWords[index] // Display correct word in unfilled areas after submit
          : droppedWords[index] || 'Drop word here'}
      </div>
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="game-layout" style={{ display: 'flex' }}>
        {/* Left Panel for Questions List */}
        <div className="left-panel" style={{ flex: 1, padding: '10px', borderRight: '1px solid #ddd' }}>
          <h2>Questions</h2>
          {[...Array(10)].map((_, i) => (
            <div key={i} style={{ marginBottom: '10px' }}>
              <button onClick={() => setCurrentSetIndex(i)}>{`Question ${i + 1}`}</button>
            </div>
          ))}
        </div>

        {/* Middle Panel for Drag-and-Drop Interaction */}
        <div className="middle-panel" style={{ flex: 2, padding: '20px' }}>
          <h1>Drag and Drop Game</h1>
          <div className="word-set">
            <h2>Base Word - {wordSets[currentSetIndex]?.baseWord}</h2>
            {droppedWords.map((_, index) => (
              <DropArea key={index} index={index} onDrop={handleWordDrop} />
            ))}
          </div>
          <div className="words" style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
            {allWords.map((word, index) => (
              <DraggableWord key={index} word={word} />
            ))}
          </div>
          <div className="button-container" style={{ marginTop: '20px' }}>
            <button onClick={handleSubmit}>Submit</button>
            <button onClick={handleNextSet} disabled={gameOver}>
              Next Question
            </button>
          </div>
        </div>

        {/* Right Panel for Question Range and Score */}
        <div className="right-panel" style={{ flex: 1, padding: '10px', borderLeft: '1px solid #ddd' }}>
          <h2>Question Range</h2>
          <div style={{ marginBottom: '20px' }}>
            {[...Array(10)].map((_, i) => (
              <div key={i} style={{ marginBottom: '10px' }}>
                <button onClick={() => setCurrentSetIndex(i)}>{`Range ${i * 10 + 1}-${(i + 1) * 10}`}</button>
              </div>
            ))}
          </div>

          <h2>Score</h2>
          <p>{score} out of 10</p>
        </div>
      </div>
    </DndProvider>
  );
};

export default DragAndDropGame;
