import React from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import NavBar from "./Components/NavBar";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import Story from "./Pages/Story";
import Wordset from "./Pages/WordSet";
import Write from "./Pages/Write";
import Spell from "./Pages/Spell";
import Dictation from "./Pages/Dictation";
import Main from "./Pages/Main.js"
import HomeCont from "./Pages/HomeContent.js"


//import NutritionPlan from "./Pages/NutritionPlan";
import Blogs from "./Pages/Blogs";

function App() {
  return (
    <>
      <Router>
        <div>
          <NavBar />
          <main>
            <Routes>
              <Route path="/" element={<HomeCont />} />
              <Route path="/story" element={<Story />} />
              <Route path="/spell" element={<Spell />} />
              <Route path="/Similar" element={<Wordset />} />
              <Route path="/write" element={<Write />} />
              <Route path="/dict" element={<Dictation />} />
              <Route path="/main" element={<Main/>} />
              <Route path="/homeCont" element={<Home/>} />
              
              <Route path="/blogs" element={<Blogs />} />
            </Routes>
          </main>
          {/* <Footer /> */}

          <a
            href="https://wa.me/6594339309"
            className="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp fontSize="40px" />
          </a>
        </div>
      </Router>
    </>
  );
}

export default App;
