import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { useNavigate } from 'react-router-dom';
import '../Assets/Css/style.css';
import Popup from './Popup';

function Game() {
  const [words, setWords] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [error, setError] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedRange, setSelectedRange] = useState([0, 10]); // Default range is 1-10
  const [isGameOver, setIsGameOver] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getRandomIncorrectMeanings = (correctMeaning, incorrectMeaning1, allMeanings) => {
      const incorrectMeanings = allMeanings.map(row => row[1]).filter(meaning => meaning !== correctMeaning && meaning !== incorrectMeaning1);
      return shuffle(incorrectMeanings).slice(0, 2);
    };

    const shuffle = (array) => {
      let currentIndex = array.length, randomIndex;
      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
      }
      return array;
    };

    if (words.length === 0 && !isDataFetched) {
      fetch('./Voca.csv')
        .then(response => response.text())
        .then(csvData => {
          const parsedData = Papa.parse(csvData, { header: false });
          let extractedWords = parsedData.data.map((row, index) => ({
            id: index,
            word: row[0],
            meaning: row[1] ? row[1] : '',
            incorrectMeaning: row[2] ? row[2] : '',
            options: shuffle([...getRandomIncorrectMeanings(row[1], row[2], parsedData.data), row[1]]).slice(0, 3)
          }));
          setWords(extractedWords);
          setIsDataFetched(true);
        })
        .catch(error => {
          console.error('Error fetching/parsing CSV file:', error);
          setError(error.message);
        });
    }
  }, [words.length, isDataFetched]);

  const handleAnswer = () => {
    const currentWord = words[currentWordIndex];
    if (currentWord.meaning === selectedOption) {
      setScore(prevScore => prevScore + 1);
    }
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    if (currentWordIndex + 1 === selectedRange[1] || currentWordIndex - selectedRange[0] >= 9) {
      setIsGameOver(true);
    } else {
      setCurrentWordIndex(prevIndex => prevIndex + 1);
    }
  };

  const handlePlayAgain = () => {
    setCurrentWordIndex(selectedRange[0]);
    setScore(0);
    setSelectedOption('');
    setShowPopup(false);
    setIsGameOver(false);
  };

  const handleRangeChange = (start, end) => {
    setSelectedRange([start, end]);
    setCurrentWordIndex(start);
    setScore(0);
    setSelectedOption('');
    setIsGameOver(false);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  const currentWords = words.slice(selectedRange[0], selectedRange[1]);

  return (
    <div className="game-container" style={{ display: 'flex' }}>
      {/* Left Panel for Questions */}
      <div className="left-panel" style={{ flex: '1', borderRight: '1px solid #ddd', padding: '10px' }}>
        <h2>Questions</h2>
        {currentWords.map((word, index) => (
          <div
            key={index}
            className={`question-item ${currentWordIndex === (selectedRange[0] + index) ? 'active' : ''}`}
            onClick={() => setCurrentWordIndex(selectedRange[0] + index)}
            style={{ cursor: 'pointer', marginBottom: '10px' }}
          >
            Question {selectedRange[0] + index + 1}
          </div>
        ))}
      </div>

      {/* Main Game Area */}
      <div className="right-panel" style={{ flex: '1', borderLeft: '1px solid #ddd', padding: '10px' }}>
        <div className="school-board">
          <h1>Welcome to the School Board</h1>
          <div className="score" style={{ float: 'right' }}>Score: {score}</div>
        </div>

        {isGameOver ? (
          <div>
            <h2>Game Over</h2>
            <p>Your final score is: {score}</p>
            <button onClick={handlePlayAgain}>Play Again</button>
          </div>
        ) : (
          <>
            <h2>Question {currentWordIndex + 1 - selectedRange[0]} of {selectedRange[1] - selectedRange[0]}</h2>
            <div className="question">
              <h2>Word: {words[currentWordIndex]?.word}</h2>
              <form onSubmit={(e) => {
                e.preventDefault();
                handleAnswer();
              }}>
                <div className="options">
                  {words[currentWordIndex]?.options.map((option, index) => (
                    <div className="option" key={index}>
                      <label>
                        <input
                          type="radio"
                          name="wordOption"
                          value={option}
                          checked={selectedOption === option}
                          onChange={(e) => setSelectedOption(e.target.value)} />
                        {option}
                      </label>
                    </div>
                  ))}
                </div>
                <button type="submit">Submit Answer</button>
              </form>
              {showPopup && (
                <Popup
                  score={score}
                  correct={words[currentWordIndex]?.meaning === selectedOption}
                  onClose={handleClosePopup} />
              )}
            </div>
          </>
        )}
      </div>

      {/* Right Panel for Question Range and Navigation */}
      <div className="right-panel" style={{ flex: '1', borderLeft: '1px solid #ddd', padding: '10px' }}>
        <h3>Question Range</h3>
        <div>
          {Array.from({ length: Math.ceil(words.length / 10) }, (_, index) => (
            <button
              key={index}
              onClick={() => handleRangeChange(index * 10, (index + 1) * 10)}
              style={{ display: 'block', margin: '10px 0' }}
            >
              {index * 10 + 1} - {(index + 1) * 10}
            </button>
          ))}
        </div>

        <h3>Next Game</h3>
        <button onClick={() => navigate('/about-us')}>Spelling Activity</button>
        <button onClick={() => navigate('/contact-us')}>Writing Activity</button>
        <button onClick={() => navigate('/dict')}> Dictation </button>
        
      </div>
    </div>
  );
}

export default Game;
