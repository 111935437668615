import React from 'react';
//import './Popup.css'; // CSS file for styling

const Popup = ({ score, correct, onClose }) => {
  return (
    <div className="popup">
      <div className="popup-inner">
        <h2>{correct ? 'Congratulations!' : 'Try again!'}</h2>
        {correct && <p>Great job!</p>}
        <p>Your final score is: {score}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default Popup;
