import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import pic from "../Assets/Images/froghopping.png";
import cat from "../Assets/Images/cat.png";

// Sample data for stories with images
const storiesData = {
  level1: [
    {
        id: 1,
        title: 'The Frog Hops',
        content: ` This is a cute little frog.
         He hops. He hops and hops.  
        He hops until he gets tired. 
        Then he rests and dreams of more hopping adventures.
  
        Imagine the frog happily dreaming under a big, shady leaf. `,
        similarWords: ['hops', 'frog'],
        // imageUrl: '../Assets/Images/froghopping.png', // Replace with actual image URL
        imageUrl: pic
      },
    {
      id: 2,
      title: 'The Cat Jumps',
      content: 'The cat jumps over the wall.',
      similarWords: ['jumps', 'cat', 'over'],
      imageUrl: cat, // Replace with actual image URL
    },
   
    // Add more stories here...
  ],
  // Add data for levels 2 to 10...
};

const ReadingComponent = () => {
  const [selectedLevel, setSelectedLevel] = useState(1);
  const [selectedStory, setSelectedStory] = useState(null);

  const handleLevelChange = (event) => {
    setSelectedLevel(Number(event.target.value));
    setSelectedStory(null); // Reset story selection when level changes
  };

  const handleStorySelect = (story) => {
    setSelectedStory(story);
  };

  const handleReadAloud = (text) => {
    const speech = new SpeechSynthesisUtterance(text);
    window.speechSynthesis.speak(speech);
  };

  const stories = storiesData[`level${selectedLevel}`] || [];

  return (
    <div style={{ display: 'flex' }}>
      {/* Left Section: Story Titles */}
      <div style={{ flex: 1, padding: '10px', borderRight: '1px solid #ccc' }}>
        <h2>Stories for Level {selectedLevel}</h2>
        {stories.map((story) => (
          <div
            key={story.id}
            onClick={() => handleStorySelect(story)}
            style={{ cursor: 'pointer', margin: '5px 0' }}
          >
            {story.title}
          </div>
        ))}
      </div>

      {/* Middle Section: Selected Story Content */}
      <div style={{ flex: 2, padding: '10px' }}>
        {selectedStory ? (
          <>
            <h2>{selectedStory.title}</h2>
            <img
              src={selectedStory.imageUrl}
              alt={selectedStory.title}
              style={{ maxWidth: '50%', height: '50%', marginBottom: '10px' }}
            />
            <p>{selectedStory.content}</p>
            <IconButton onClick={() => handleReadAloud(selectedStory.content)} aria-label="read aloud">
              <VolumeUpIcon />
            </IconButton>
          </>
        ) : (
          <p>Select a story to view its content.</p>
        )}
      </div>

      {/* Right Section: Similar Words */}
      <div style={{ flex: 1, padding: '10px', borderLeft: '1px solid #ccc' }}>
        <h2>Similar Words</h2>
        {selectedStory ? (
          <ul>
            {selectedStory.similarWords.map((word, index) => (
              <li key={index}>{word}</li>
            ))}
          </ul>
        ) : (
          <p>Select a story to see similar words.</p>
        )}
      </div>

      {/* Level Selector */}
      <div style={{ marginTop: '20px' }}>
        <label htmlFor="level-select">Select Level:</label>
        <select id="level-select" value={selectedLevel} onChange={handleLevelChange}>
          {[...Array(10).keys()].map((level) => (
            <option key={level + 1} value={level + 1}>
              Level {level + 1}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default ReadingComponent;
