import React from 'react';

function ProjectDescription() {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Kids Learning Game App</h1>
      <p style={styles.intro}>
        This interactive app is designed to make language learning engaging and enjoyable for children. 
        It offers a range of educational games that help kids build vocabulary, improve spelling, and develop listening skills. 
        The app includes four primary game modes, each with unique objectives to keep young learners entertained while they practice essential language skills.
      </p>

      <h2 style={styles.sectionTitle}>Key Features:</h2>
      
      <div style={styles.feature}>
        <h3 style={styles.featureTitle}>1. Word Dictation Game</h3>
        <p style={styles.featureDescription}>
          The Word Dictation Game helps children develop their listening and spelling skills. Users can either upload a list of words 
          or manually input words, which are then spoken aloud at regular intervals. Kids listen to the words and write them down, 
          reinforcing spelling and auditory skills in a fun, interactive way. After the session, children can review the words they spelled, 
          allowing for self-assessment and improvement.
        </p>
      </div>

      <div style={styles.feature}>
        <h3 style={styles.featureTitle}>2. Vocabulary Builder</h3>
        <p style={styles.featureDescription}>
          This game encourages vocabulary expansion and retention. Kids can upload a list of new words and their meanings, 
          creating a personalized vocabulary library. The app provides a quiz option where kids answer multiple-choice questions 
          based on their word list, helping them retain vocabulary effectively.
        </p>
      </div>

      <div style={styles.feature}>
        <h3 style={styles.featureTitle}>3. Find the Missing Letter Game</h3>
        <p style={styles.featureDescription}>
          Children are presented with words missing one letter, challenging them to identify and insert the correct letter. 
          This game is a fun way to improve spelling and attention to detail. With each correct answer, kids gain confidence 
          in word formation and spelling.
        </p>
      </div>

      <div style={styles.feature}>
        <h3 style={styles.featureTitle}>4. Similar Word Game</h3>
        <p style={styles.featureDescription}>
          This game challenges kids to find words that are similar in meaning or closely related in sound. 
          It’s a playful approach to teaching synonyms and word associations, making language learning more dynamic. 
          Kids are encouraged to think creatively and expand their understanding of words.
        </p>
      </div>

      {/* <h2 style={styles.sectionTitle}>Project Objectives:</h2>
      <p style={styles.description}>
        The goal of this app is to offer children a stimulating, self-paced language learning experience. 
        By incorporating multiple game modes, the app addresses different aspects of language development – from spelling 
        and vocabulary to auditory skills and comprehension.
      </p>

      <h2 style={styles.sectionTitle}>Educational Benefits:</h2>
      <ul style={styles.benefitsList}>
        <li>Improves Listening Skills</li>
        <li>Builds Vocabulary</li>
        <li>Strengthens Spelling Abilities</li>
        <li>Boosts Critical Thinking</li>
      </ul> */}
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // Align items to the start instead of center
    maxWidth: '800px',
    margin: '20px auto', // Center horizontally
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    color: '#333',
    backgroundColor: '#f9f9f9',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  },
  title: {
    fontSize: '32px',
    fontWeight: 'bold',
    color: '#2c3e50',
    marginBottom: '20px',
    textAlign: 'left', // Align to the left for responsiveness
  },
  intro: {
    fontSize: '18px',
    marginBottom: '20px',
    color: '#555',
    textAlign: 'left', // Align to the left for responsiveness
  },
  sectionTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#34495e',
    marginBottom: '15px',
    borderBottom: '2px solid #ddd',
    paddingBottom: '5px',
  },
  feature: {
    marginBottom: '20px',
    width: '100%', // Ensure feature section takes full width
  },
  featureTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#2980b9',
    marginBottom: '10px',
  },
  featureDescription: {
    fontSize: '16px',
    color: '#666',
  },
  description: {
    fontSize: '16px',
    color: '#666',
    marginBottom: '20px',
  },
  benefitsList: {
    fontSize: '16px',
    color: '#555',
    listStyleType: 'disc',
    paddingLeft: '20px',
  },
};

export default ProjectDescription;
