import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import '../Assets/Css/style.css';
import { useNavigate } from 'react-router-dom';

function WritingActivity() {
  const [words, setWords] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [typedWord, setTypedWord] = useState('');
  const [score, setScore] = useState(0);
  const [error, setError] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30);
  const [selectedRange, setSelectedRange] = useState([0, 10]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch('./Voca.csv')
      .then(response => response.text())
      .then(csvData => {
        const parsedData = Papa.parse(csvData, { header: false });
        setWords(parsedData.data.map(row => row[0]));
      })
      .catch(error => {
        console.error('Error fetching/parsing CSV file:', error);
        setError(error.message);
      });
  }, []);

  useEffect(() => {
    if (timeLeft === 0) {
      handleNextWord();
    }

    const timer = setInterval(() => {
      setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const handleSpeak = () => {
    const utterance = new SpeechSynthesisUtterance(words[currentWordIndex]);
    speechSynthesis.speak(utterance);
  };

  const handleCheckAnswer = () => {
    const currentWord = words[currentWordIndex];
    if (typedWord.trim().toLowerCase() === currentWord.trim().toLowerCase()) {
      setScore(prevScore => prevScore + 1);
      alert('Correct!');
    } else {
      alert(`Incorrect! The correct answer is "${currentWord}"`);
    }
    handleNextWord();
  };

  const handleNextWord = () => {
    setShowAnswer(false);
    setTypedWord('');
    setCurrentWordIndex(prevIndex => prevIndex + 1);
    setTimeLeft(30);
  };

  const handleShowAnswer = () => {
    setShowAnswer(true);
  };

  const handlePlayAgain = () => {
    setTypedWord('');
    setCurrentWordIndex(0);
    setScore(0);
    setShowAnswer(false);
    setTimeLeft(30);
  };

  const handleRangeSelect = (start, end) => {
    setSelectedRange([start, end]);
    setCurrentWordIndex(start);
  };

  const rangeButtons = [];
  for (let i = 0; i < words.length; i += 10) {
    rangeButtons.push(
      <button key={i} onClick={() => handleRangeSelect(i, i + 10)}>
        {i + 1}-{i + 10}
      </button>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const currentWords = words.slice(selectedRange[0], selectedRange[1]);
  const isGameOver = currentWordIndex >= selectedRange[1] || currentWordIndex >= words.length;

  return (
    <div className="game-container" style={{ display: 'flex' }}>
      <div className="left-panel" style={{ flex: '1', borderRight: '1px solid #ddd', padding: '10px' }}>
        <h2>Questions</h2>
        {currentWords.map((word, index) => (
          <div
            key={index}
            className={`question-item ${currentWordIndex === (selectedRange[0] + index) ? 'active' : ''}`}
            onClick={() => setCurrentWordIndex(selectedRange[0] + index)}
            style={{ cursor: 'pointer', marginBottom: '10px' }}
          >
            Question {selectedRange[0] + index + 1}
          </div>
        ))}
      </div>

      <div className="main-panel" style={{ flex: '2', padding: '20px' }}>
        <h1>Writing Activity</h1>
        <div className="score">Score: {score}</div>
        <div className="timer">Time left: {timeLeft}s</div>
       
        {!isGameOver ? (
         
            <div>
              <button onClick={handleSpeak}>
                <span role="img" aria-label="speaker">🔊</span> Hear the word
              </button>
           
            <input
              type="text"
              value={typedWord}
              onChange={(e) => setTypedWord(e.target.value)}
              placeholder="Type the word here"
            />
            <button onClick={handleCheckAnswer}>Submit</button>
            <button onClick={handleShowAnswer}>Show Answer</button>
            {showAnswer && <p>The correct answer is: {words[currentWordIndex]}</p>}
          </div>
        ) : (
          <div>
            <h2>Game Over</h2>
            <p>Your final score is: {score} out of {selectedRange[1] - selectedRange[0]}</p>
            <button onClick={handlePlayAgain}>Play Again</button>
          </div>
        )}

      </div>
      <div className="right-panel" style={{ flex: '1', borderLeft: '1px solid #ddd', padding: '10px' }}>
      <h2>Word {currentWordIndex + 1} of {words.length}</h2>
      <h3>Next Game</h3>
      <div>{rangeButtons}</div>
        <button onClick={() => navigate('/about-us')}>Spelling Activity</button>
        <button onClick={() => navigate('/Services')}>Writing Activity</button>
        <button onClick={() => navigate('/bookings')}>Similar Words</button>
      </div>
    </div>
  );
}

export default WritingActivity;
